exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advance-js": () => import("./../../../src/pages/advance.js" /* webpackChunkName: "component---src-pages-advance-js" */),
  "component---src-pages-beginners-js": () => import("./../../../src/pages/beginners.js" /* webpackChunkName: "component---src-pages-beginners-js" */),
  "component---src-pages-cancellationpolicy-js": () => import("./../../../src/pages/cancellationpolicy.js" /* webpackChunkName: "component---src-pages-cancellationpolicy-js" */),
  "component---src-pages-childrens-js": () => import("./../../../src/pages/childrens.js" /* webpackChunkName: "component---src-pages-childrens-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporatekriya-js": () => import("./../../../src/pages/corporatekriya.js" /* webpackChunkName: "component---src-pages-corporatekriya-js" */),
  "component---src-pages-depression-js": () => import("./../../../src/pages/depression.js" /* webpackChunkName: "component---src-pages-depression-js" */),
  "component---src-pages-eshwaridhyana-js": () => import("./../../../src/pages/eshwaridhyana.js" /* webpackChunkName: "component---src-pages-eshwaridhyana-js" */),
  "component---src-pages-eshwarikriya-js": () => import("./../../../src/pages/eshwarikriya.js" /* webpackChunkName: "component---src-pages-eshwarikriya-js" */),
  "component---src-pages-guruji-js": () => import("./../../../src/pages/guruji.js" /* webpackChunkName: "component---src-pages-guruji-js" */),
  "component---src-pages-hormones-js": () => import("./../../../src/pages/hormones.js" /* webpackChunkName: "component---src-pages-hormones-js" */),
  "component---src-pages-iandyougreen-js": () => import("./../../../src/pages/iandyougreen.js" /* webpackChunkName: "component---src-pages-iandyougreen-js" */),
  "component---src-pages-immortalprocess-js": () => import("./../../../src/pages/immortalprocess.js" /* webpackChunkName: "component---src-pages-immortalprocess-js" */),
  "component---src-pages-immunity-js": () => import("./../../../src/pages/immunity.js" /* webpackChunkName: "component---src-pages-immunity-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meditation-js": () => import("./../../../src/pages/meditation.js" /* webpackChunkName: "component---src-pages-meditation-js" */),
  "component---src-pages-nervoussystem-js": () => import("./../../../src/pages/nervoussystem.js" /* webpackChunkName: "component---src-pages-nervoussystem-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-pain-js": () => import("./../../../src/pages/pain.js" /* webpackChunkName: "component---src-pages-pain-js" */),
  "component---src-pages-physiology-js": () => import("./../../../src/pages/physiology.js" /* webpackChunkName: "component---src-pages-physiology-js" */),
  "component---src-pages-pricingpolicy-js": () => import("./../../../src/pages/pricingpolicy.js" /* webpackChunkName: "component---src-pages-pricingpolicy-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refundpolicy-js": () => import("./../../../src/pages/refundpolicy.js" /* webpackChunkName: "component---src-pages-refundpolicy-js" */),
  "component---src-pages-relationship-js": () => import("./../../../src/pages/relationship.js" /* webpackChunkName: "component---src-pages-relationship-js" */),
  "component---src-pages-relaxation-js": () => import("./../../../src/pages/relaxation.js" /* webpackChunkName: "component---src-pages-relaxation-js" */),
  "component---src-pages-sleep-js": () => import("./../../../src/pages/sleep.js" /* webpackChunkName: "component---src-pages-sleep-js" */),
  "component---src-pages-srikrishi-js": () => import("./../../../src/pages/srikrishi.js" /* webpackChunkName: "component---src-pages-srikrishi-js" */),
  "component---src-pages-srinaada-js": () => import("./../../../src/pages/srinaada.js" /* webpackChunkName: "component---src-pages-srinaada-js" */),
  "component---src-pages-stress-js": () => import("./../../../src/pages/stress.js" /* webpackChunkName: "component---src-pages-stress-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-weightloss-js": () => import("./../../../src/pages/weightloss.js" /* webpackChunkName: "component---src-pages-weightloss-js" */),
  "component---src-pages-wellness-js": () => import("./../../../src/pages/wellness.js" /* webpackChunkName: "component---src-pages-wellness-js" */),
  "component---src-pages-wisdom-js": () => import("./../../../src/pages/wisdom.js" /* webpackChunkName: "component---src-pages-wisdom-js" */)
}

